module.exports = {
  faqs: [
    {
      question: "1. Can I see a demo of how Notifizz works?",
      answer:
        "Absolutely! We offer demos that showcase how Notifizz works for both customers and marketing/product teams. You can experience it in action.",
    },
    {
      question: "2. What is Notifizz, and how can it benefit my SAAS business?",
      answer:
        "Notifizz is a Notification Center that you add directly in your SAAS Product. Once connected, your users can read notifications that you send to them. It boosts customer activation, retention, and conversion. It provides a seamless way to engage your SAAS customers and leads.",
    },
    {
      question:
        "3. How quickly can I set up and start using Notifizz for my SAAS product?",
      answer:
        "You can install Notifizz in minutes, thanks to its 'plug and play' solution. No lengthy setup processes or delays – you'll be up and running swiftly. Even if your are not a developer.",
    },
    {
      question: "4. What kind of notifications can I send using Notifizz?",
      answer:
        "Notifizz send notification directly in your Product, in the Notification center we are providing. In future, Notifizz will progressively support email, sms and all other communication channels.",
    },
    {
      question: "5. Who is suppose to be the user of Notifizz?",
      answer:
        "Notifizz is created to be used by Product, Marketing and Communication Teams. It's plug and play, except the tracking of event where you have to connect with existing event. Segment can be used for this purpose or you can copy code the code generated by Notifizz in your app.",
    },
    {
      question:
        "6. Is there any limit on the number of notifications I can send with Notifizz?",
      answer:
        "Notifizz offers scalable plans to accommodate your needs. A free plan is coming soon. Specified quota and paid plans are available for higher volumes of notifications. For now, pricing are only communicated on demand. We are focusing on customer onboarding and experience first.",
    },
  ],
};
