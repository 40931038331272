import React from "react";

const backgroundTop = (
  <div
    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
    aria-hidden="true"
  >
    <img
      src="background.svg"
      style={{ height: "1500px", width: "100%", opacity: "11%" }}
      alt=""
    />
  </div>
);

const whatsNew = (
  <div className="mt-24 sm:mt-32 lg:mt-16">
    <a href="#" className="inline-flex space-x-6">
      <span className="rounded-full bg-purple-600/10 px-3 py-1 text-sm font-semibold leading-6 text-purple-600 ring-1 ring-inset ring-purple-600/10">
        Powerful Notification Center embedded in your web app
      </span>
    </a>
  </div>
);

const backgroundBottom = (
  <div
    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
    aria-hidden="true"
  >
    <div
      className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#0ea5e9] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
      style={{
        clipPath:
          "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
      }}
    />
  </div>
);

if (!navigator.userAgent.includes("ReactSnap")) {
  window.Inapply.getConfig().displayMode.set("page");
  window.Inapply.getConfig().demo.set(true);
  window.Inapply.getConfig().theme.set("amberTheme");
  window.Inapply.getConfig().style.roundedButton.set(true);
}

export default function Hero3({ switchOpen }) {
  return (
    <div className="relative isolate overflow-hidden bg-white">
      {backgroundTop}
      <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-2xl">
          <div className="mt-24 sm:mt-32 lg:mt-16"></div> {/* {whatsNew} */}
          <h1 className="mt-34 tracking-normal text-center text-2xl font-bold leading-5 sm:leading-tight text-gray-900 sm:text-5xl">
            Turn signups{" "}
            <br />
            <span className="bg-gradient-to-r from-[#DB1783] to-orange-500 bg-clip-text text-transparent">
               into loyal customers</span>{" "}
          </h1>
          <p className="mt-7 text-center text-xl text-gray-600">
            <span className="leading-7">
              Supercharge customer <b>activation</b>, <b>retention</b>, and{" "}
              <b>conversion</b>
              <br />
              with effective user notifications.
            </span>
          </p>
          <div className="mt-10 items-center gap-x-6 text-center">
            <a
              onClick={() => switchOpen()}
              className="select-none cursor-pointer rounded-md bg-gradient-to-r from-[#DB1783] to-orange-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:brightness-110 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
            >
              Join beta program →
            </a>
          </div>
        </div>
        <div className="mx-auto mt-16 max-w-4xl sm:mt-24">
          <img
            src="video-snapshot-v2.png"
            alt="Notifizz demo"
            className="mb-[-14%] rounded-xl shadow-xl ring-1 ring-white/10 transition duration-300 ease-in"
          />
        </div>
        {/*
        <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:max-w-none lg:flex-none xl:ml-32">
          <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
            <div className="relative -m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
              <inapply-notifications></inapply-notifications>
            </div>
          </div>
        </div>
        */}
      </div>
    </div>
  );
}
