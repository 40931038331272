import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  LockClosedIcon,
} from "@heroicons/react/20/solid";

const features = [
  {
    name: "Activation",
    comingSoon: false,
    description: (
      <span>
        <b>Engage users to complete core product actions</b>, like completing
        profile, making a purchase or configure his account. Core action
        completion in a product is key to increase Life time value of the user.
      </span>
    ),
    href: "#",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Retention",
    comingSoon: true,
    description: (
      <span>
        <b>Resurect your inactive users</b>. Communicate with your users through
        multiple channels : Realtime embedded Notification center, Mail, Sms and
        others.
        <br />
        <br />
        Give them <b>good reasons to come back daily</b>. Send them relevant
        content, information and offers to bring them back. Never spam them,
        they keep control of their fine grained Notification preferences.
      </span>
    ),
    href: "#",
    icon: LockClosedIcon,
  },
  {
    name: "Referral",
    comingSoon: true,
    description: (
      <span>
        <b>Inform your users that you reward them if they invite friends</b>.
        Referral works if you propose the right reward at the right step of user
        journey.
        <br />
        <br />
        Native integration of Notifizz with products like ViralLoop or Tolt
        allow you to notify your users when they accomplish core actions and
        unlock potential of referral.
      </span>
    ),
    href: "#",
    icon: ArrowPathIcon,
  },
];

export default function Example() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Perfect match with{" "}
            <span className="bg-gradient-to-r from-[#DB1783] to-orange-500 bg-clip-text text-transparent">
              AARRR Framework
            </span>
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Quis tellus eget adipiscing convallis sit sit eget aliquet quis.
            Suspendisse eget egestas a elementum pulvinar et feugiat blandit at.
            In mi viverra elit nunc.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                  <feature.icon
                    className="h-5 w-5 flex-none text-orange-600"
                    aria-hidden="true"
                  />
                  {feature.name}
                  {feature.comingSoon && (
                    <span className="rounded-full bg-orange-600/10 px-3 py-0.5 text-sm font-semibold leading-6 text-orange-600 ring-1 ring-inset ring-orange-600/10">
                      coming soon
                    </span>
                  )}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
