import {
  CloudArrowUpIcon,
  CommandLineIcon,
  InboxStackIcon,
  LockClosedIcon,
  ServerIcon,
  StarIcon,
} from "@heroicons/react/20/solid";

/*
1. Unlimited notification content possibilities
2. Notification Center customization
3. Live preview notification editor
4. Inform in real-time, when event happens (web and mobile screenshot)
5. Give your users power on their notification management
*/

const useCases = [
  {
    name: "Read / Unread",
    description: "Native support of read / unread notifications and counter",
    icon: InboxStackIcon,
  },
  {
    name: "Realtime",
    description: "Inform users when event happens, not minutes or hours later",
    icon: StarIcon,
  },
  {
    name: "Integrated in minutes",
    description: "Integrate Notifizz in your web app in minutes",
    icon: CommandLineIcon,
  },
];

const features = [
  {
    name: "Unlimited notification content possibilities",
    description: "Lorem ipsitiis ratione.",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Notification Center customization",
    description: "Anim ",
    icon: LockClosedIcon,
  },
  {
    name: "Live preview notification editor",
    description: "Ac t",
    icon: ServerIcon,
  },
  {
    name: "Inform in real-time, when event happens",
    description: "Ac",
    icon: ServerIcon,
  },
  {
    name: "Give your users power on their notification management",
    description: "Ac",
    icon: ServerIcon,
  },
];

const codeString =
  '<script src="in-app-notification-center.js" />\n' +
  "<script>\n" +
  "  center.init({})\n" +
  "</script>";

export default function ForCustomers() {
  return (
    <div className="mt-32 overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
          <div className="px-6 md:px-0 lg:pr-4 lg:pt-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              <h2 className="mt-14 text-3xl font-bold tracking-normal text-gray-900 sm:text-4xl">
                Realtime Notification experience for your{" "}
                <span className="bg-gradient-to-r from-[#DB1783] to-orange-500 bg-clip-text text-transparent">
                  leads and customers
                </span>
              </h2>
              <p className="mt-6 text-gray-500 sm:text-xl">
                Implement a <b>Full featured</b> realtime Notification center to
                notify your users <b>directly in your web app</b>.
              </p>
              <p className="mt-6 text-gray-500 sm:text-xl">
                Reach highest notification standard, used by{" "}
                <b>Digital Ocean</b>, <b>Atlassian</b>, <b>LinkedIn</b>,{" "}
                <b>Mailchimp</b>, <b>Gmail</b> and so many others products.
              </p>
            </div>
          </div>
          <div className="sm:px-6 lg:px-0">
            <div className="mt-6 w-96 flex-none">
              <div className="relative -m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                <inapply-notifications></inapply-notifications>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto mt-32 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
        <dl className="mt-32 grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
          {useCases.map((useCase) => (
            <div key={useCase.name} className="relative pl-16">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-gradient-to-r from-[#DB1783] to-orange-500">
                  <useCase.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                {useCase.name}
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">
                {useCase.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
