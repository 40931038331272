import React from "react";

const backgroundTop = (
  <div
    className="absolute bottom-0 right-0 transform-gpu overflow-hidden blur-3xl"
    aria-hidden="true"
  >
    <img
      src="background.svg"
      style={{ height: "600px", opacity: "20%" }}
      alt=""
    />
  </div>
);

export default function UnlimitedPossibilities({switchOpen}) {
  return (
    <div className="relative isolate">
      {backgroundTop}
      <div
        className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
        aria-hidden="true"
      ></div>
      <div className="overflow-hidden">
        <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
          <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
            <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Unlimited content possibilities.
              </h2>
              <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                Empower your teams to prioritize creativity and drive user
                activation and retention with ease.
                <br />
                <br />
              </p>
              <div className="mt-10 items-center gap-x-6">
                <a
                    onClick={() => switchOpen()}
                  className="select-none cursor-pointer rounded-md bg-gradient-to-r from-[#DB1783] to-orange-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:brightness-110 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
                >
                  Join beta program
                </a>
              </div>
            </div>

            {/* FIXME : Replace with one large image instead of html flex. Easier disposition on resize */}
            <div className="mt-14 hidden justify-end gap-8 sm:-mt-44 sm:flex sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
              <div className="ml-auto w-80 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                <div className="relative">
                  <img
                    src="/notifications-images/notif-1.png"
                    alt=""
                    className="aspect-[6/2] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <div className="relative left-20">
                  <img
                    src="/notifications-images/notif-2.png"
                    alt=""
                    className="aspect-[6/2] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <div className="relative">
                  <img
                    src="/notifications-images/notif-3.png"
                    alt=""
                    className="aspect-[6/2] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
              </div>
              <div className="mr-auto flex-none space-y-8 sm:mr-0 sm:pt-20 lg:pt-20">
                <div className="relative -left-40 w-96">
                  <img
                    src="/notifications-images/notif-3.png"
                    alt=""
                    className="aspect-[6/2] rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <div className="relative w-64">
                  <img
                    src="/notifications-images/notif-1.png"
                    alt=""
                    className="mt-10 aspect-[6/2] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
              </div>
            </div>
          </div>

          <div className="flex sm:hidden">
            <div className="w-80 flex-none space-y-8 pt-20">
              <div className="relative">
                <img
                  src="/notifications-images/notif-1.png"
                  alt=""
                  className="aspect-[6/2] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                />
                <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="relative left-10">
                <img
                  src="/notifications-images/notif-2.png"
                  alt=""
                  className="aspect-[6/2] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                />
                <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="relative">
                <img
                  src="/notifications-images/notif-3.png"
                  alt=""
                  className="aspect-[6/2] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                />
                <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
