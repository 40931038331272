module.exports = {
  logo: {
    img: "/logo.svg",
    href: "/",
  },
  companyName: "Notifizz",
  navigation: [
    { name: "For teams", href: "/" },
    { name: "For SAAS", href: "/" },
    { name: "Pricing", href: "/" },
  ],
};
