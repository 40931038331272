import {
  CommandLineIcon,
  InboxStackIcon,
  StarIcon,
  CurrencyDollarIcon,
  BanknotesIcon,
  UserCircleIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/20/solid";
import React from "react";

/*
1. Unlimited notification content possibilities
2. Notification Center customization
3. Live preview notification editor
4. Inform in real-time, when event happens (web and mobile screenshot)
5. Give your users power on their notification management
*/

const backgroundTop = (
  <div
    className="absolute inset-0 transform-gpu overflow-hidden blur-3xl"
    aria-hidden="true"
  >
    <img
      src="background.svg"
      style={{ height: "400px", opacity: "100%" }}
      alt=""
    />
  </div>
);

export default function BusinessImpact() {
  return (
    <div className="mx-auto mt-32 max-w-7xl overflow-hidden bg-white md:px-6 lg:px-8">
      <h2 className="text-2xl text-center font-bold tracking-tight text-gray-900 sm:text-4xl">
        Update more. Engage more. {' '}
        <span className="bg-gradient-to-r from-[#DB1783] to-orange-500 bg-clip-text text-transparent">
          Sell more.
        </span>
      </h2>
      <ul
        role="list"
        className="grid h-[25rem] grid-cols-1 gap-4 py-10 sm:grid-cols-2 lg:max-w-none lg:grid-cols-4"
      >
        <li className="overflow-hidden rounded-2xl bg-gradient-to-r from-[#DB1783]/80 to-orange-500/80 px-8 py-10 text-white shadow-lg">
          <div className="z-50 bg-transparent">
            <CurrencyDollarIcon className="h-14" />
            <h3 className="pt-2 text-base font-semibold leading-7 tracking-tight">
              More conversion, more revenue
            </h3>
            <p className="pt-2 text-sm leading-6 text-gray-200">
              Description Description Description Description Description
              Description Description Description Description Description
            </p>
          </div>
        </li>
        <li className="overflow-hidden rounded-2xl bg-gradient-to-r from-gray-50 to-gray-100 px-8 py-10 shadow shadow-lg">
          <div className="z-50 bg-transparent">
            <div className="h-14">
              <BanknotesIcon className="h-12" />
            </div>

            <h3 className="pt-2 text-base font-semibold leading-7 tracking-tight">
              Save development costs
            </h3>
            <p className="pt-2 text-sm leading-6 text-gray-400">Description</p>
          </div>
        </li>
        <li className="overflow-hidden rounded-2xl bg-gradient-to-r from-orange-100 to-orange-50 px-8 py-10 shadow shadow-lg">
          <div className="z-50 bg-transparent">
            <div className="h-14">
              <AdjustmentsHorizontalIcon className="h-12" />
            </div>
            <h3 className="pt-2 text-base font-semibold leading-7 tracking-tight">
              Better user experience
            </h3>
            <p className="pt-2 text-sm leading-6 text-gray-400">Description</p>
          </div>
        </li>
        <li className="overflow-hidden rounded-2xl bg-gradient-to-r from-pink-50 to-pink-100 px-8 py-10 shadow shadow-lg">
          <div className="z-50 bg-transparent">
            <UserCircleIcon className="h-14" />
            <h3 className="pt-2 text-base font-semibold leading-7 tracking-tight">
              Product and Market teams in control
            </h3>
            <p className="pt-2 text-sm leading-6 text-gray-400">
              Notification design and content at your fingertips. <br />
              <br />
              Nocode solution.
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
}
