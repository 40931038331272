import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function JoinBetaProgram({ open, setOpen }) {
  const cancelButtonRef = useRef(null);

  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [project, setProject] = useState('')

  function submit(event) {
    event.preventDefault()

    fetch('https://hook.eu2.make.com/pbdaue9vyy5vp3amvl5jeth33rql0zd6', {
      method: 'POST',
      body: JSON.stringify({email, name, project}),
      headers: { "Content-Type": "application/json"}
    }).then(r => {
      setEmail('')
      setProject('')
      setName('')
      setOpen(false)
    });
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <form method="POST" name="contactform" onSubmit={(e) => submit(e)}>

                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h1"
                      className="text-xl font-semibold leading-9 text-gray-900"
                    >
                      Notifizz is currently available only on-demand during its beta phase.<br/>
                      <span className="bg-gradient-to-r from-[#DB1783] to-orange-500 bg-clip-text text-transparent">Join or Request a demo!</span>
                    </Dialog.Title>
                    <div className="mt-6">
                          <input type="hidden" name="form-name" value="contact" />

                          <div className="sm:col-span-4">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                          Your email *
                        </label>
                        <div className="mt-2">
                          <input
                              id="email"
                              name="email"
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required={true}
                              placeholder="Email"
                              autoComplete="email"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3 mt-4">
                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                          Your name
                        </label>
                        <div className="mt-2">
                          <input
                              type="text"
                              name="name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              placeholder="Name"
                              id="name"
                              autoComplete="family-name"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="col-span-full mt-4">
                        <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                            Tell us more about your project
                        </label>
                        <div className="mt-2">
                          <textarea
                              id="about"
                              name="about"
                              rows={3}
                              value={project}
                              onChange={(e) => setProject(e.target.value)}
                              autoComplete="off"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder={'What is your company, and how do you plan to utilize the Notification Center?'}
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="submit"
                    className="cursor-pointer rounded-md bg-gradient-to-r from-green-600 to-green-800 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:brightness-110 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
                  >
                    Join now
                  </button>
                </div>
              </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
