import React, { useState } from "react";
import Arrr from "./section/arrr";
import Header from "../shared/section/header";
import Footer from "../shared/section/footer";
import Faq from "../shared/section/faq";
import UnlimitedPossibilities from "./section/unlimited-possibilities";
import Hero3 from "./section/hero-v3";
import ForCustomers from "./section/for-customers";
import ForTeams from "./section/for-teams";
import CustomerUseCases from "./section/use-cases";
import BusinessImpact from "./section/business-impact";
import JoinBetaProgram from "../shared/modal/join-beta-program";
import SeeVideo from "../shared/modal/see-video";
import ForAll from "./section/for-all";

export default function Home() {
  const [open, setOpen] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  function switchOpen() {
    setOpen(!open);
  }

  function switchOpenVideo() {
    setOpenVideo(!openVideo);
  }

  return (
    <div>
      <Header switchOpen={switchOpen} />
      <Hero3 switchOpen={switchOpen} />
      <ForCustomers />
      <CustomerUseCases />
      <BusinessImpact />
      <Faq />
      <ForTeams switchOpenVideo={switchOpenVideo} />
      <div>Notification Command Control</div>
      <Arrr />
      <UnlimitedPossibilities switchOpen={switchOpen} />
      <Footer />
      <JoinBetaProgram open={open} setOpen={setOpen} />
      <SeeVideo open={openVideo} setOpen={setOpenVideo} />
    </div>
  );
}
