import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";

const features = [
  {
    name: "Push to deploy.",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: CloudArrowUpIcon,
  },
  {
    name: "SSL certificates.",
    description:
      "Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.",
    icon: LockClosedIcon,
  },
  {
    name: "Database backups.",
    description:
      "Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.",
    icon: ServerIcon,
  },
];

export default function ForTeams({switchOpenVideo}) {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:ml-auto lg:pl-4 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                <span className="bg-gradient-to-r from-[#DB1783] to-orange-500 bg-clip-text text-transparent">
                  The simplest Notification Editor ever made.
                </span>
              </h2>
              <div className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                For Product and Marketing teams
              </div>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Intuitive, with live preview.
                <br />
                No need for coding.
                <br />
                Manage variables in notification content with ease.
                <br />
                Perfect for Product and Market teams with no engineering skills.
                <br />
                Integrate in product with copy paste.
              </p>
            </div>
          </div>
          <div className="flex items-start justify-end lg:order-first">
            <img onClick={() => switchOpenVideo()}
              src="/video-snapshot-v2.png"
              alt="Product screenshot"
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
              width={1216}
              height={721}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
